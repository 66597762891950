<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="2">
          <b-button class="mb-3 mt-4" variant="info" :to="{name:routeNames.BUY}"><b-icon-arrow-left-circle></b-icon-arrow-left-circle> Back</b-button>
        </b-col>
        <b-col>
          <SecuredByPaypal></SecuredByPaypal>
        </b-col>
      </b-row>
      <hr>
      <b-row cols="1" cols-md="2">
        <b-col>
          <b-row>
            <b-col md="2">
              <b-img thumbnail width="100px" :src="require('@/assets/images/image-book/practical-go-lessons-book.jpg')"
                     alt="Practical Go Lessons Book"></b-img>
            </b-col>
            <b-col >
              <div v-if="model === modelEnums.HARD_BW">
                <b-row>
                  <b-col md="8">
                    <strong>Practical Go Lessons - Paper Edition Black & White</strong>
                  </b-col>
                  <b-col>
                    <b-skeleton width="30%" v-if="loading"></b-skeleton>
                    {{ modelPrice }}
                  </b-col>
                </b-row>
                <!-- BW -->
                <small class="text-secondary">
                  <DescriptionBookBW></DescriptionBookBW>
                </small>
              </div>
              <div v-if="model === modelEnums.HARD_COLOR">
                <b-row>
                  <b-col md="8">
                    <strong>Practical Go Lessons - Paper Edition Color</strong>
                  </b-col>
                  <b-col>
                    <b-skeleton width="30%" v-if="loading"></b-skeleton>
                    {{ modelPrice }}
                  </b-col>
                </b-row>
                <!-- COLOR -->
                <small class="text-secondary">
                  <DescriptionBookColor></DescriptionBookColor>
                </small>
              </div>
              <div v-if="model === modelEnums.BUNDLE_BW">
                <b-row>
                  <b-col md="8">
                    <strong>Practical Go Lessons - Paper Edition Black & White + Digital Edition</strong>
                  </b-col>
                  <b-col>
                    <b-skeleton width="30%" v-if="loading"></b-skeleton>
                    {{ modelPrice }}
                  </b-col>
                </b-row>
                <!-- BUNDLE BW -->
                <small class="text-secondary">
                  <DescriptionBookBW></DescriptionBookBW>
                  <p>+ The digital Edition</p>
                  <DescriptionBookDigital></DescriptionBookDigital>
                </small>
              </div>
              <div v-if="model === modelEnums.BUNDLE_COLOR">
                <b-row>
                  <b-col md="8">
                    <strong>Practical Go Lessons - Paper Edition Color + Digital Edition</strong>
                  </b-col>
                  <b-col>
                    <b-skeleton width="30%" v-if="loading"></b-skeleton>
                    {{ modelPrice }}
                  </b-col>
                </b-row>
                <!-- BUNDLE BW -->
                <small class="text-secondary">
                  <DescriptionBookColor></DescriptionBookColor>
                  <p>+ The digital Edition</p>
                  <DescriptionBookDigital></DescriptionBookDigital>
                </small>
              </div>
              <div v-if="model === modelEnums.DIGITAL">
                <b-row>
                  <b-col md="8">
                    <strong>Practical Go Lessons - Digital Edition</strong>
                  </b-col>
                  <b-col>
                    <b-skeleton width="30%" v-if="loading"></b-skeleton>
                    {{ modelPrice }}
                  </b-col>
                </b-row>
                <!-- COLOR -->
                <small class="text-secondary">
                  <DescriptionBookDigital></DescriptionBookDigital>
                </small>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="model !== modelEnums.DIGITAL">
            <b-col md="2">
            </b-col>
            <b-col>
              <b-row>
                <b-col md="8">
                  <p><strong>Subtotal</strong></p>
                </b-col>
                <b-col>
                  <b-skeleton width="30%" v-if="loading"></b-skeleton>
                  {{ modelPrice }}
                </b-col>
              </b-row>
              <hr>
            </b-col>
          </b-row>
          <b-row class="text-secondary" v-if="model !== modelEnums.DIGITAL">
            <b-col md="2">
            </b-col>
            <b-col>
              <b-row>
                <b-col md="8">
                  Shipping to {{countryName}}
                </b-col>
                <b-col>
                  <b-skeleton width="30%" v-if="loading"></b-skeleton>
                  {{ shippingPrice }}
                </b-col>
              </b-row>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
            </b-col>
            <b-col>
              <b-row>
                <b-col md="8">
                  <p>Total Due</p>
                </b-col>
                <b-col>
                  {{ totalPrice }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="mt-2">
          <!--ADDRESS -->
          <div v-if="this.model !== 'DIGITAL'">
            <hr>
            <h4>Shipping Information</h4>
            <hr>
            <AddressForm v-if="showAddressForm"  :show-country-selector="true" :address="this.address" v-on:on-submitted="onAddressSubmitted" v-on:on-change-address="onChangeAddress" ></AddressForm>
          </div>
          <!--PAYPAL BUTTON -->
          <div v-if="paymentInProgress" class="text-center" >
            <b-spinner  variant="primary" label="Text Centered"></b-spinner> Payment in progress... Please wait...
          </div>
          <div v-if="paypalButtonLoading" class="text-center" >
            <b-spinner  variant="primary" label="Text Centered"></b-spinner> Loading Paypal Button... Please wait...
          </div>

          <br>
          <p v-if="paypalLoadedOnce && !paypalButtonLoading && showPaypalHelper" class="text-info"> <b-icon-arrow-down></b-icon-arrow-down> Click on the Button to launch Payment</p>
          <div id="paypal-button-container"></div>

          <br>
          <br>
          <br>
        </b-col>
      </b-row>
    </b-container>
  </div>


</template>

<script>
import Consts from "@/consts"
import ScriptLoader from "@/scriptLoader";
import Webservice from "@/webservice";
import DescriptionBookBW from "@/components/DescriptionBookBW";
import DescriptionBookColor from "@/components/DescriptionBookColor";
import AddressForm from "@/components/AddressForm";
import {BIconArrowLeftCircle} from "bootstrap-vue";
import SecuredByPaypal from "@/components/SecuredByPaypal";
import DescriptionBookDigital from "@/components/DescriptionBookDigital";
import { BIconArrowDown } from 'bootstrap-vue'

export default {
  name: "CheckoutPaypal",
  components: {
    DescriptionBookDigital,
    SecuredByPaypal, AddressForm, DescriptionBookColor, DescriptionBookBW,BIconArrowLeftCircle,BIconArrowDown},
  data() {
    return {
      showPaypalHelper: false,
      paypalButtonLoading: false,
      paypalLoadedOnce : false,
      showValidateAddressButton: false,
      paymentInProgress: false,
      loading:false,
      showAddressForm:true,
      showAddressRecap:false,
      model: this.$route.query.model,
      currency: this.$route.query.currency,
      countryCode: this.$route.query.countryCode,
      routeNames: Consts.ROUTE_NAMES,
      modelEnums: Consts.BOOK_TYPES_ENUM,
      totalPrice: "",
      modelPrice: "",
      shippingPrice: "",
      countryName : "",
      address: {
        countryName : "",
        name: "",
        line1: "",
        line2: "",
        adminArea1: "",
        adminArea2: "",
        postalCode: "",
        countryCode: ""
      },
    }
  },
  computed: {
  },
  methods: {
    tooglePaypalButton(){
      let x = document.getElementById("paypal-button-container");
      if (x.style.display === "none") {
        this.showPaypalHelper = true
        x.style.display = "block";
      } else {
        this.showPaypalHelper = false
        x.style.display = "none";
      }
    },
    onChangeAddress(){
      this.tooglePaypalButton()
    },
    onAddressSubmitted(){
      if(!this.paypalLoadedOnce){
        this.loadPaypalButton(this.currentlyOrderedItemType)
      } else {
        this.tooglePaypalButton()
      }
    },
    loadPaypalButton() {
      this.paypalButtonLoading = true
      ScriptLoader.loadPaypal(this.currency).then(() => {
        this.paypalLoadedOnce = true
        window["paypal-"+this.currency].Buttons({
          createOrder: () => {
            this.paymentInProgress = true
            return Webservice.createCheckout({
              provider: "PAYPAL",
              model: this.model,
              currency: this.currency,
              shippingAddress: this.address,
              shippingCountryCode: this.countryCode
            }).then((res) => {
              return res.data.sessionId
            }).catch((err)=>{
              if (err.response.status === 400){
                this.makeToast("Error", "The shipping address is not valid. Please double check it", "danger")
              } else {
                this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
              }
            })
          },
          onError:()=> {
            this.paymentInProgress = false
          },
          onCancel:()=> {
            this.paymentInProgress = false
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then(() => {
              this.$router.push({name: Consts.ROUTE_NAMES.SUCCESS})
            });
          },
          style: {
            layout: 'horizontal',
            size: 'small',
            color: 'gold',
            shape: 'pill',
            label: 'checkout',
            tagline: 'true'
          }
        }).render('#paypal-button-container').then(() => {
          // button loaded
          this.paypalButtonLoading = false
        });
      }).catch(() => {
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },

  },
  created() {
    this.loading = true
    window.scrollTo(0, 0);
    this.address.countryCode = this.countryCode
    Webservice.getCheckoutDetails({
      model: this.model,
      currency : this.currency,
      countryCode : this.countryCode
    }).then((res)=>{
      this.totalPrice = res.data.total
      this.shippingPrice = res.data.shipping
      this.modelPrice = res.data.item
      this.countryName = res.data.countryName
      this.address.countryName = this.countryName
      if(this.model === Consts.BOOK_TYPES_ENUM.DIGITAL){
        // load directly paypal
        this.loadPaypalButton()
      }


      this.loading = false
    }).catch(()=>{
      this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
    })


  },
}
</script>

<style scoped>
.disabled-overlay {
   background-color: white;
   opacity: .6;
   z-index: 1000;
}
</style>
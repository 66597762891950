<template>
  <ul class="list-unstyled mt-3 mb-4">
    <li>700+ Pages</li>
    <li>All source code examples (in a .zip file)</li>
    <li>DRM Free</li>
    <li><strong>PDF</strong> file</li>
    <li><strong>EPUB</strong> file</li>
    <li><strong>MOBI</strong> file</li>
    <li>Free updates sent to your mailbox</li>
  </ul>
</template>

<script>
export default {
name: "DescriptionBookDigital"
}
</script>

<style scoped>

</style>
<template>
  <div>
    <ul class="list-unstyled mt-3 mb-4">
      <li>700+ Pages</li>
      <li>Printed in Europe</li>
      <li>All source code examples (in a .zip file)</li>
      <li>Shipped by mail to the address of your choice</li>
    </ul>
    <ul class="list-unstyled mt-3 mb-4">
      <li><strong>Size:</strong> 17cm * 24 cm</li>
      <li><strong>Binding:</strong> Hard heat-sealed binding professional</li>
      <li><strong>Paper:</strong> 90gr - Classic uncoated - Wood free</li>
      <li><strong>Weight:</strong> approx. 1,47 kg</li>
      <li><strong>Cover:</strong> Mat laminating</li>
    </ul>
  </div>
</template>

<script>
export default {
name: "DescriptionBookBW"
}
</script>

<style scoped>

</style>